<template>
  <div class="overallInfo-blockWrapper enrollments-tover">
    <div class="">
      <div class="overallInfo-content">
        <h3>Enrollments & Terms Overview (By Year)</h3>
      </div>
      <div class="row">
        <apexchart
          type="area"
          height="350"
          width="100%"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "EnrollmentsTermsOverviewByYear",
  data() {
    return {
      enrollmentInfo: [],
      previousYearNo: "",
      series: [],
    };
  },
  methods: {
    async getEnrollmentOverview() {
      const url = "v2/rep-analytics/enrollment-report-all";
      await Api.get(url)
        .then((res) => {
          this.enrollmentInfo = res.data.data;
          let seriesInfo = [
            {
              name: "Enrollments",
              data: [
                this.getEnrollMent(3),
                this.getEnrollMent(2),
                this.getEnrollMent(1),
                this.getEnrollMent(0),
              ],
            },
            {
              name: "Avg. Enrollments",
              data: [
                this.getAverageEnrollment(3),
                this.getAverageEnrollment(2),
                this.getAverageEnrollment(1),
                this.getAverageEnrollment(0),
              ], //[3yrs ago, 2yrs ago, 1yr ago, this year]
            },
            {
              name: "Avg. Terms",
              data: [
                this.getAverageTermed(3),
                this.getAverageTermed(2),
                this.getAverageTermed(1),
                this.getAverageTermed(0),
              ],
            },
            {
              name: "Terms",
              data: [
                this.getTermed(3),
                this.getTermed(2),
                this.getTermed(1),
                this.getTermed(0),
              ],
            },
          ];
          this.series = seriesInfo;
        })
        .catch(() => {
          this.enrollmentInfo = "";
        })
        .finally(() => {
          this.$emit("hide-loader", "enrollmentTermsOverviewByYear");
        });
    },
    getYearsArray(y) {
      let to = new Date().getFullYear();
      if (y == 0) {
        return [to.toString()];
      }
      if(y == 1){
        return [(to -1).toString()];
      }
      let from = to - y + 1;
      let yearArr = [];
      for (let i = from; i <= to; i++) {
        yearArr.push(i.toString());
      }
      return yearArr;
    },
    getEnrollMent(year) {
      let years = this.getYearsArray(year);
      let count = 0;
      this.enrollmentInfo.forEach((data) => {
        if (years.includes(data.label)) {
          count = count + Number(data.total);
        }
      });
      return count.toFixed(2);
    },
    getAverageEnrollment(year) {
      let years = this.getYearsArray(year);
      let count = 0;
      this.enrollmentInfo.forEach((data) => {
        if (years.includes(data.label)) {
          count = count + Number(data.average_enrollmenmt);
        }
      });
      return count.toFixed(2);
    },
    getAverageTermed(year) {
      let years = this.getYearsArray(year);
      let count = 0;
      this.enrollmentInfo.forEach((data) => {
        if (years.includes(data.label)) {
          count = count + Number(data.termed_avg);
        }
      });
      return count.toFixed(2);
    },
    getTermed(year) {
      let years = this.getYearsArray(year);
      let count = 0;
      this.enrollmentInfo.forEach((data) => {
        if (years.includes(data.label)) {
          count = count + Number(data.termed);
        }
      });
      return count.toFixed(2);
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: "2",
        },
        xaxis: {
          type: "category",
          categories: ["3 Years Ago", "2 Years Ago", "Last Year", "This Year"],
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        legend: {
          show: true,
          position: "left",
        },
        yaxis: {
          decimalsInFloat: 0,
        },
      };
    },
  },
  created() {
    this.getEnrollmentOverview();
  },
};
</script>