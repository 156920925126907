var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper rep-analytics-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title split"
  }, [_c('h1', [_vm._v("Rep Analytics")]), _c('rep-selector')], 1), _c('br'), _c('div', {
    staticClass: "direct"
  }, [_vm._m(0), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('NewMemberByMonth', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('OverallTotals', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('OverallTotalMembers', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)])]), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('EnrollmentsByMonth', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('OverallTotalpolicies', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('EnrollmentsTermsOverviewByYear', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)]), _c('div', {
    staticClass: "row mb-3 rep-analytics-third"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('TotalMembersByIndividualPlan', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('TotalActiveByState', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  }), _c('br'), _c('TotalMembersByPlan', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h5', [_vm._v("Direct Analytics")])]);
}]

export { render, staticRenderFns }