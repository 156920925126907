<template>
  <div class="page-wrapper rep-analytics-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title split">
        <h1>Rep Analytics</h1>
        <rep-selector />
      </div>
      <br />
      <div class="direct">
        <div class="page-title">
          <h5>Direct Analytics</h5>
        </div>
        <div class="row mb-3">
          <div class="col-md-7">
            <NewMemberByMonth @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <div class="row">
              <OverallTotals @hide-loader="updateFlag" />
            </div>
            <br />
            <div class="row">
              <OverallTotalMembers @hide-loader="updateFlag" />
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-7">
            <EnrollmentsByMonth @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <OverallTotalpolicies @hide-loader="updateFlag" />
          </div>
          <div class="col-12">
            <EnrollmentsTermsOverviewByYear @hide-loader="updateFlag" />
          </div>
        </div>
        <div class="row mb-3 rep-analytics-third">
          <div class="col-md-7">
            <TotalMembersByIndividualPlan @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <TotalActiveByState @hide-loader="updateFlag" />
            <br />
            <TotalMembersByPlan @hide-loader="updateFlag" />
          </div>
        </div>
      </div>
    </div>
    <!-- <member-plan-modal /> -->
  </div>
</template>
<script>
import OverallTotals from "./partials/OverallTotals.vue";
import OverallTotalMembers from "./partials/OverallTotalMembers.vue";
import NewMemberByMonth from "./partials/NewMemberByMonth.vue";
import EnrollmentsByMonth from "./partials/EnrollmentsByMonth.vue";
import OverallTotalpolicies from "./partials/OverallTotalpolicies.vue";
import EnrollmentsTermsOverviewByYear from "./partials/EnrollmentsTermsOverviewByYear.vue";
import TotalMembersByIndividualPlan from "./partials/TotalMembersByIndividualPlan.vue";
import TotalActiveByState from "./partials/TotalActiveByState.vue";
import TotalMembersByPlan from "./partials/TotalMembersByPlan.vue";
import RepSelector from "./RepSelector.vue";

export default {
  name: "RepAnalytics",
  components: {
    OverallTotals,
    OverallTotalMembers,
    NewMemberByMonth,
    EnrollmentsByMonth,
    OverallTotalpolicies,
    EnrollmentsTermsOverviewByYear,
    TotalMembersByIndividualPlan,
    TotalActiveByState,
    TotalMembersByPlan,
    RepSelector,
  },
  data() {
    return {
      repAnalytics: {
        enrollmentByMonth: false,
        enrollmentTermsOverviewByYear: false,
        newMemberByMonth: false,
        totalActiveByState: false,
        totalMembersByIndividualPlan: false,
        totalMembersByPlan: false,
        overallTotals: false,
        overallTotalPolicies: false,
        overallTotalMembers: false,
      },
      isLoading: true,
      loaderIcon: "",
      breadcrumbItems: [{ title: "Rep Analytics", active: true }],
    };
  },
  methods: {
    updateFlag(key) {
      // console.log("Emitted From", key);
      this.repAnalytics[key] = true;
      this.loadingStatus();
    },
    loadingStatus() {
      if (this.isLoading) {
        this.loaderIcon = this.$loading.show();
        this.isLoading = false;
      }
      let flags = Object.values(this.repAnalytics);
      if (flags.every((flag) => flag === true)) {
        this.loaderIcon.hide();
      }
    },
  },
  created() {
    this.loadingStatus();
  },
};
</script>
<style src="../../assets/css/repanalytics.css"></style>
